/**
 * Helper class for executing some async function in bulk with debounce
 * Both T and R generics must be defined. T is the input item added to the queue and R is the result.
 * The bulkOperation function provied in the constructor options must be a function that takes in the bulk queue and
 * calls res() or rej() on each of the item with some result.
 */
export default class BulkOperation<T, R>{
    private _delay: number;
    private _bulkDebounce: number | null = null;

    private _bulkPromise: Promise<void> | null = null;
    private _bulkQueue: BulkOperationItem<T, R>[][] = [];
    private _bulkOperationValues: BulkOperationItem<T, R>[] | null = null;
    private _bulkOperation: (pQueue: BulkOperationItem<T, R>[]) => Promise<void>;
    private _bulkSize: number;

    get bulkPromise() { return this._bulkPromise; }

    constructor(pOptions: {
        /** Function implementing the bulk operation */
        bulkOperation: (pQueue: BulkOperationItem<T, R>[]) => Promise<void>;
        /**
         * Debounce between each bulk operation
         * @default 100
         */
        delay?: number;
        /**
         * Max number of items in a bulk operation
         * @default 200
         */
        bulkSize?: number;
    }) {
        this._delay = pOptions.delay ?? 100;
        this._bulkOperation = pOptions.bulkOperation;
        this._bulkSize = pOptions.bulkSize ?? 200;
    }

    /**
     * Add an item to bulk operation queue. Returns the promise that will be
     * resolved or rejected by the bulk operation function.
     */
    async addToQueue(pItem: T): Promise<R | undefined> {
        if (pItem == null) { return Promise.resolve(undefined); }
        if (this._bulkPromise) { await this._bulkPromise; }
        if (this._bulkDebounce) { window.clearTimeout(this._bulkDebounce); }
        return new Promise<R>((res, rej) => {
            if (this._bulkOperationValues == null) { this._bulkOperationValues = []; }
            if (this._bulkOperationValues.length < this._bulkSize) {
                // Current bulk is smaller than max limit
                this._bulkOperationValues.push({
                    value: pItem,
                    res: res,
                    rej: rej
                });
            } else {
                // Current bulk is larger than max limit, push to queue
                if (this._bulkQueue.at(-1)) {
                    // Bulk queue has values, check if last bulk has space
                    if (this._bulkQueue.at(-1)!.length < this._bulkSize) {
                        // Bulk is smaller than limit, push to it
                        this._bulkQueue.at(-1)!.push({
                            value: pItem,
                            res: res,
                            rej: rej
                        });
                    } else {
                        // Bulk is at max limit, push to new one
                        this._bulkQueue.push([{
                            value: pItem,
                            res: res,
                            rej: rej
                        }]);
                    }
                } else {
                    // Queue is empty, push new one
                    this._bulkQueue.push([{
                        value: pItem,
                        res: res,
                        rej: rej
                    }]);
                }
            }
            const initOperationDebounce = () => {
                this._bulkDebounce = window.setTimeout(() => {
                    let resolve = () => {};
                    this._bulkPromise = new Promise((res) => { resolve = res; });
                    this._bulkOperation(this._bulkOperationValues!).catch(ex => {
                        this._bulkOperationValues?.forEach(item => item.rej(ex));
                    }).finally(() => {
                        this._bulkOperationValues = null;
                        this._bulkDebounce = null;
                        resolve();
                        this._bulkPromise = null;
                        if (this._bulkQueue.length > 0) {
                            this._bulkOperationValues = this._bulkQueue.splice(0, 1)[0];
                            initOperationDebounce();
                        }
                    });
                }, this._delay);
            };

            initOperationDebounce();
        });
    }
}

type BulkOperationItem<T, R> = { value: T; res: (data: R) => void, rej: (ex: unknown) => void }
